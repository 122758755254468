/*!

=========================================================
* Material Dashboard PRO React - v1.9.0 Based on Material Dashboard PRO v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


// variables and mixins
@import "material-dashboard-pro-react/variables";
@import "material-dashboard-pro-react/mixins";
@import "material-dashboard-pro-react/shadows";

// plugin css
@import "material-dashboard-pro-react/plugins/plugin-nouislider";
@import "material-dashboard-pro-react/plugins/plugin-perfect-scrollbar";
@import "material-dashboard-pro-react/plugins/plugin-react-datetime";
@import "material-dashboard-pro-react/plugins/plugin-react-bootstrap-sweetalert";
@import "material-dashboard-pro-react/plugins/plugin-react-chartist";
@import "material-dashboard-pro-react/plugins/plugin-react-big-calendar";
@import "material-dashboard-pro-react/plugins/plugin-react-jvectormap";
@import "material-dashboard-pro-react/plugins/plugin-react-table";
@import "material-dashboard-pro-react/plugins/plugin-react-tagsinput";

// Core CSS
@import "material-dashboard-pro-react/misc";
@import "material-dashboard-pro-react/fileupload";
@import "material-dashboard-pro-react/fixed-plugin";


// customized by Claudio

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.2rem 0.2em !important;
  font-size: 14px !important;
  color: #2f2f2f; 
  font-weight: normal;
  }
  
  .p-component{
    font-size: 14px !important;
  }

.MuiSelect-select {
  background-color: white !important;
}

.MuiInput-root {
  background-color: white !important;
}

.MuiInputBase-input  {
  padding: 3px 0 3px !important 
}

div.MuiInputBase-multiline {
  background-color: white !important;
}

.MuiButton-contained {
  background-color: #070452 !important;
  color:white !important;
}

.MuiButtonGroup-groupedContained:not(:last-child){
  border-color: #227eee !important;
}


.MuiButton-containedPrimary {
  background-color: #6a6a6a !important;
}

.MuiButtonGroup-groupedContainedPrimary:not(:last-child){
  border-color: #9e9e9e !important;
}


.MuiButton-containedSecondary {
  background-color: #812007 !important;
}

.MuiButtonGroup-groupedContainedSecondary:not(:last-child){
  border-color: #f15d38 !important;
}





.MuiFab-primary{
  background-color:  #6a6a6a  !important;
}

H4{
  font-weight: bold !important;
  font-size: 25px !important;
}

.fulldate > div {
  padding: 0 !important;
  margin-top: 11px !important;
}

.yearPicker > div{
    width: fit-content !important;
    height: 28px !important;
    padding-left: 0px !important;
}


label.MuiFormLabel-root {
  z-index: 1;
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
}

div.p-field-radiobutton label {
  margin-bottom: 0px;
}

.year-picker{
  margin-top: 0px !important;
}

.input > div{
  padding-top: 0px !important;
}
.calendarParagraph{
  margin-bottom: 0px !important;
  margin-top: 0px  !important;
  margin: 0px !important;
}

.calendarCustomedForMUI{
    width: 100%;
    height: 26px;
    margin-top: 0px;
    border-radius: 0px !important;
    border: 0px !important;
}

.dropdownCustomisedForMUI{
  width: 100%;
  height: 26px;
  margin-top: 0px;
  border-radius: 0px !important;
  border: 0px !important;
}

.dropdownCustomisedForMUIGian{
  width: 100%;
  height: 38px;
  margin-top: 0px;
  border-radius: 0px !important;
  border: 0px !important;
}

.dropdownElementsStyle > div{
  width: 40px;
  
}

 .dropdownCustomisedForMUI .p-dropdown-label.p-inputtext{
  opacity: 1;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.42857;
  padding: 4px;
  color: black;
 
 }

.inputAziendaXModificaCorrisp > div{
  padding-top: 6px !important;
}
 
.headerinput > div {
  padding-top: 8px !important;
}

.radio {
  margin-right: 10px !important;
}

div.MuiGrid-item>div.p-dropdown {
  width: 100% !important;
}
div.MuiGrid-item>div.p-multiselect {
  width: 100% !important;
}
div.MuiGrid-item>span.p-autocomplete {
  width: 100% !important;
}  
div.MuiGrid-item>div.MuiTextField-root{
  width: 100% !important;
}

.ctrlMuiToPF{  
  background: #ffffff !important;
  border: 1px solid #ced4da !important;
  -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s !important;
  border-radius: 3px !important;
  padding: 3px !important;
}
.ctrlMuiToPF>.MuiInput-underline:before {
  border-bottom: 0px !important;
}

.MuiInput-underline:before { 
  border-bottom: 0px !important;
}

div.MuiSelect-root.MuiSelect-select {
  font-size: 14px;
}

.MuiInputBase-root input[type="date"]
{
  font-size: 14px;
  padding: 5px 0px !important;
}
.editinputdate{
  height: 35px !important;
}

.dateField{
  padding-top: 0px !important;
}

.total{
  float: right;
}
.ctrlMuiToPF>.MuiInput-underline:after {
  border-bottom: 0px !important;
}

.lblNoMargin{margin: 0px !important;}

.lblNoMarginExport{margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 38px !important;}

div.CustomHeightSelect>div.MuiInput-formControl {
  margin-top: 23px !important;
}

.inputReadOnly input {
  background-color: #f3f3b1 !important;
}
.inputReadOnly.MuiSelect-root.MuiSelect-select {
  background-color: #f3f3b1 !important;
}

.labelCheckbox{
  color: black !important;
}

.corrispondezafields > div{
  padding-top: 20px !important;
}

.statistichecheckbox{
  padding: 0px !important;
}

.statistichelabel {
  margin-right: 12px !important;
}

table {
  text-transform: uppercase;
}

input {
  text-transform: uppercase;
}

.MuiToolbar-root .MuiButton-label{
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
.matricolaAutocomplete input::-webkit-outer-spin-button,
.matricolaAutocomplete input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.matricolaAutocomplete input[type=number] {
  -moz-appearance: textfield;
}