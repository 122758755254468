.container {
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 999;
}

.card {
  border-radius: 10px;
  background-color: rgb(255, 255, 255, 0.5);
  padding: 4vh;
}

.input_container {
  display: flex;
  flex-direction: column;
}
.label {
  color: #ffffff;
  font-family: Verdana;
  font-size: 1.1em;
}
.input {
  margin-top: 1vh;
  border-radius: 20px;
  border: 2px solid #cccccc;
  padding: 1vh;
  padding-left: 2vh;
  padding-right: 2vh;
  text-transform: none !important;
}

.input_error {
  border: 2px solid #ff7979;
  background-color: #ffbcbc !important;
}

.button_container {
  display: flex;
  justify-content: center;
}
.button {
  width: 100%;
  padding: 1vh;
  border-radius: 50px;
  outline: none;
  border: none;
  margin-top: 3vh;
  transition: all 0.3s;
}
.button:hover {
  background-color: #cccccc;
}
