.component {
  margin-top: 5vh;
  width: 55vh;
}

.row {
  margin-top: 0vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn_container {
  margin-top: 3vh;
  display: flex;
  justify-content: center;
}

.btn {
  padding: 1.5vh;
  padding-left: 5vh;
  padding-right: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 100px;
  background-color: #505050;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
}
.btn:hover {
  cursor: pointer;
  background-color: rgb(26, 26, 26);
}

.disabled {
  color: #4d4d4d;
  background-color: #cccccc;
}
.disabled:hover {
  cursor: not-allowed;
  background-color: #cccccc;
}

.icon {
  font-size: 1.5em;
  margin-right: 1vh;
}

.password_container {
  margin-top: 5vh;
}
