.ulList {
  z-index: 101;
  position: fixed;
  left: 59vw;
  width: 40vw;
  top: 15vh;
  list-style-type: none;
}

.ulList li {
  margin-top: 10px;
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 500ms linear;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms linear;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms linear;
}

.fade-exit-done {
  opacity: 0;
}
