.tooltip-add {
  font-size: 42px !important;
}

.date > div {
  padding: 0 !important;
  /* margin-top: 14px !important; */
}

.select > div {
  margin-top: 0 !important;
  padding-top: 10px !important;
}

.slide > div {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.switch > div {
  /* margin-left: 20px !important; */
  margin-right: 20px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.actionButton {
  margin: 0 0 0 5px;
  padding: 5px;
}

.actionButton svg,
actionButton.fab,
actionButton.fas,
actionButton.far,
actionButton.fal,
actionButton.material-icons {
  margin-right: 0px;
}

.actionButtonRound {
  width: auto;
  height: auto;
  min-width: auto;
}

.icon {
  vertical-align: middle;
  width: 17px;
  height: 17px;
  top: -1px;
  position: relative;
}

.btnRound {
  width: 15px !important;
  margin: 0px !important;
  padding: 4px 11px 4px 15px !important;
}

.colDelete {
  width: 65px;
}

.colDocActions {
  width: 110px;
}

.label {
  color: #565656 !important;
  font-size: 16px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  position: relative !important;
  top: 4px !important;
}

p.lblNoMargin, p.calendarParagraph {
  color: #565656 !important;
  font-size: 16px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  position: relative !important;
  top: 4px !important;
}

span.calendarCustomedForMUI input.p-inputtext.p-component {
  color: black !important;
}