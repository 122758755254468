.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.account_img_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dddddd;
  padding: 5vh;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.icon {
  font-size: 5em;
  color: #4d4d4d;
}

.accountName {
  margin-top: 1vh;
  color: blue;
}
